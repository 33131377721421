<template>
    <div
        v-if="trip"
        class="flex flex-col items-start justify-start font-400 text-base text-neutral-1000 sm:h-8 sm:flex-row sm:items-center"
    >
        <div>{{ trip.location.name }}</div>
        <div class="mx-4 hidden w-px self-stretch bg-neutral-200 sm:block" />
        <ClientOnly>{{ dateString }}</ClientOnly>
    </div>
</template>

<script setup lang="ts">
import { Trip } from '~/types/api'

const props = defineProps<{
    trip: Trip
}>()

const dateString = computed(() => {
    // format to 1 Jun 2024 to 21 Jun 2024
    return formatDateRangeAsAtoB(props.trip.startDate, props.trip.endDate)
})
</script>
